// Avoid `console` errors in browsers that lack a console.
;(function() {
    var method;
    var noop = function () {};
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());

/*\
|*|
|*|  :: cookies.js ::
|*|
|*|  A complete cookies reader/writer framework with full unicode support.
|*|
|*|  https://developer.mozilla.org/en-US/docs/DOM/document.cookie
|*|
|*|  This framework is released under the GNU Public License, version 3 or later.
|*|  http://www.gnu.org/licenses/gpl-3.0-standalone.html
|*|
|*|  Syntaxes:
|*|
|*|  * docCookies.setItem(name, value[, end[, path[, domain[, secure]]]])
|*|  * docCookies.getItem(name)
|*|  * docCookies.removeItem(name[, path])
|*|  * docCookies.hasItem(name)
|*|  * docCookies.keys()
|*|
\*/

var docCookies = {
	getItem: function(sKey) {
		return unescape(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + escape(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
	},
	setItem: function(sKey, sValue, vEnd, sPath, sDomain, bSecure) {
		if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
			return false;
		}
		var sExpires = "";
		if (vEnd) {
			switch (vEnd.constructor) {
				case Number:
					sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
					break;
				case String:
					sExpires = "; expires=" + vEnd;
					break;
				case Date:
					sExpires = "; expires=" + vEnd.toGMTString();
					break;
			}
		}
		document.cookie = escape(sKey) + "=" + escape(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
		return true;
	},
	removeItem: function(sKey, sPath) {
		if (!sKey || !this.hasItem(sKey)) {
			return false;
		}
		document.cookie = escape(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sPath ? "; path=" + sPath : "");
		return true;
	},
	hasItem: function(sKey) {
		return (new RegExp("(?:^|;\\s*)" + escape(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
	},
	keys: /* optional method: you can safely remove it! */ function() {
		var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
		for (var nIdx = 0; nIdx < aKeys.length; nIdx++) {
			aKeys[nIdx] = unescape(aKeys[nIdx]);
		}
		return aKeys;
	}
};

/**
 * Global namespace
 */
var Neo = Neo || {};

/**
 * Método utilitário para a criação de namespaces
 * @param string name
 */
Neo.namespace = function(name){

	var parts	= name.split('.');
	var current = Neo;

	for (var i in parts){

		if (!current[ parts[i] ]){
			current[ parts[i] ] = {};
		}

		current = current[ parts[i] ];

	}

};


/**
 * Previne que o form seja enviado múltiplas vezes
 */
// $(function() {
// 	$( 'form' ).not('.js-ajax-form').preventDoubleSubmit();
// });


/**
 * Abre um link externo em uma nova janela
 */
$(function(){

	$( ".js-link-externo" ).click(function( e ){

		e.preventDefault();

		window.open( $(this).attr( 'href' ) );

	});

});

/**
 * Previne vários cliques em um mesmo link
 */
$(function() {

	$('.js-prevent-clicks').on('click', function(e){

		//se não existe uma requisição em andamento
		if( ! $('.js-prevent-clicks').data('clicado') )
		{
			 $(this).data('clicado', true);
			 $(this).after('<i class="fa fa-refresh fa-spin"></i>');
			 // console.log('liberado');
		}
		else
		{
			e.preventDefault();
			// console.log('bloqueado');
		}

	});

});

/**
 * Manipula o Grid Displayer (bookmarlet)
 */
$(function(){

	//Abre Grid Displayer
	var abreGridDisplayer = function() {
		var head = document.getElementsByTagName("head")[0];
		var bklScript = document.createElement("script");
		bklScript.type = "text/javascript";
		bklScript.src = "http://alefeuvre.github.com/foundation-grid-displayer/gd-bookmarklet.js";
		head.appendChild(bklScript);
	};

	//Manipula o Grid Displayer evitando o click no bookmarlet
	$(document).keyup(function(e) {

		//tecla '/' abre grid
		if( e.keyCode == 191 ){
			abreGridDisplayer();
		}

		//tecla esc fecha grid
		if (e.keyCode == 27){
			$('#gdt-close').trigger('click');
		}

	});

});


/*
 * ----------------------------------------------------------------------------
 * Envio de form por Ajax: form plugin e validate plugin
 * ----------------------------------------------------------------------------
 */

/*
 * Translated default messages for the jQuery validation plugin.
 * Locale: PT (Portuguese; português)
 * Region: BR (Brazil)
 */
$.extend($.validator.messages, {
	required: "Este campo &eacute; requerido.",
	remote: "Este email já está cadastrado.",
	email: "Por favor, forne&ccedil;a um endere&ccedil;o de email v&aacute;lido.",
	url: "Por favor, forne&ccedil;a uma URL v&aacute;lida.",
	date: "Por favor, forne&ccedil;a uma data v&aacute;lida.",
	dateISO: "Por favor, forne&ccedil;a uma data v&aacute;lida (ISO).",
	number: "Por favor, forne&ccedil;a um n&uacute;mero v&aacute;lido.",
	digits: "Por favor, forne&ccedil;a somente d&iacute;gitos.",
	creditcard: "Por favor, forne&ccedil;a um cart&atilde;o de cr&eacute;dito v&aacute;lido.",
	equalTo: "Por favor, forne&ccedil;a o mesmo valor novamente.",
	extension: "Por favor, forne&ccedil;a um valor com uma extens&atilde;o v&aacute;lida.",
	maxlength: $.validator.format("Por favor, forne&ccedil;a n&atilde;o mais que {0} caracteres."),
	minlength: $.validator.format("Por favor, forne&ccedil;a ao menos {0} caracteres."),
	rangelength: $.validator.format("Por favor, forne&ccedil;a um valor entre {0} e {1} caracteres de comprimento."),
	range: $.validator.format("Por favor, forne&ccedil;a um valor entre {0} e {1}."),
	max: $.validator.format("Por favor, forne&ccedil;a um valor menor ou igual a {0}."),
	min: $.validator.format("Por favor, forne&ccedil;a um valor maior ou igual a {0}."),
	nifES: "Por favor, forne&ccedil;a um NIF v&aacute;lido.",
	nieES: "Por favor, forne&ccedil;a um NIE v&aacute;lido.",
	cifEE: "Por favor, forne&ccedil;a um CIF v&aacute;lido.",
	postalcodeBR: "Por favor, forne&ccedil;a um CEP v&aacute;lido.",
	cpfBR: "Por favor, forne&ccedil;a um CPF v&aacute;lido."
});

/*
 * Brazillian CPF number (Cadastrado de Pessoas Físicas) is the equivalent of a Brazilian tax registration number.
 * CPF numbers have 11 digits in total: 9 numbers followed by 2 check numbers that are being used for validation.
 */
$.validator.addMethod("cpfBR", function(value) {
	// Removing special characters from value
	value = value.replace(/([~!@#$%^&*()_+=`{}\[\]\-|\\:;'<>,.\/? ])+/g, "");

	// Checking value to have 11 digits only
	if (value.length !== 11) {
		return false;
	}

	var sum = 0,
		firstCN, secondCN, checkResult, i;

	firstCN = parseInt(value.substring(9, 10), 10);
	secondCN = parseInt(value.substring(10, 11), 10);

	checkResult = function(sum, cn) {
		var result = (sum * 10) % 11;
		if ((result === 10) || (result === 11)) {result = 0;}
		return (result === cn);
	};

	// Checking for dump data
	if (value === "" ||
		value === "00000000000" ||
		value === "11111111111" ||
		value === "22222222222" ||
		value === "33333333333" ||
		value === "44444444444" ||
		value === "55555555555" ||
		value === "66666666666" ||
		value === "77777777777" ||
		value === "88888888888" ||
		value === "99999999999"
	) {
		return false;
	}

	// Step 1 - using first Check Number:
	for ( i = 1; i <= 9; i++ ) {
		sum = sum + parseInt(value.substring(i - 1, i), 10) * (11 - i);
	}

	// If first Check Number (CN) is valid, move to Step 2 - using second Check Number:
	if ( checkResult(sum, firstCN) ) {
		sum = 0;
		for ( i = 1; i <= 10; i++ ) {
			sum = sum + parseInt(value.substring(i - 1, i), 10) * (12 - i);
		}
		return checkResult(sum, secondCN);
	}
	return false;

}, "Por favor digite um CPF válido");


/**
 * Return true, if the value is a valid date, also making this formal check dd/mm/yyyy.
 *
 * @example $.validator.methods.date("01/01/1900")
 * @result true
 *
 * @example $.validator.methods.date("01/13/1990")
 * @result false
 *
 * @example $.validator.methods.date("01.01.1900")
 * @result false
 *
 * @example <input name="pippo" class="{dateITA:true}" />
 * @desc Declares an optional input element whose value must be a valid date.
 *
 * @name $.validator.methods.dateITA
 * @type Boolean
 * @cat Plugins/Validate/Methods
 */
$.validator.addMethod("dateBR", function(value, element) {
	var check = false,
		re = /^\d{1,2}\/\d{1,2}\/\d{4}$/,
		adata, gg, mm, aaaa, xdata;
	if ( re.test(value)) {
		adata = value.split("/");
		gg = parseInt(adata[0], 10);
		mm = parseInt(adata[1], 10);
		aaaa = parseInt(adata[2], 10);
		xdata = new Date(Date.UTC(aaaa, mm - 1, gg, 12, 0, 0, 0));
		if ( ( xdata.getUTCFullYear() === aaaa ) && ( xdata.getUTCMonth () === mm - 1 ) && ( xdata.getUTCDate() === gg ) ) {
			check = true;
		} else {
			check = false;
		}
	} else {
		check = false;
	}
	return this.optional(element) || check;
}, $.validator.messages.date);

// http://jqueryvalidation.org/creditcard-method/
// based on http://en.wikipedia.org/wiki/Luhn_algorithm
$.validator.addMethod( "creditcard", function( value, element ) {
	if ( this.optional( element ) ) {
		return "dependency-mismatch";
	}

	// Accept only spaces, digits and dashes
	if ( /[^0-9 \-]+/.test( value ) ) {
		return false;
	}

	var nCheck = 0,
		nDigit = 0,
		bEven = false,
		n, cDigit;

	value = value.replace( /\D/g, "" );

	// Basing min and max length on
	// http://developer.ean.com/general_info/Valid_Credit_Card_Types
	if ( value.length < 13 || value.length > 19 ) {
		return false;
	}

	for ( n = value.length - 1; n >= 0; n-- ) {
		cDigit = value.charAt( n );
		nDigit = parseInt( cDigit, 10 );
		if ( bEven ) {
			if ( ( nDigit *= 2 ) > 9 ) {
				nDigit -= 9;
			}
		}

		nCheck += nDigit;
		bEven = !bEven;
	}

	return ( nCheck % 10 ) === 0;
}, "Por favor digite um número de cartão válido." );

// Older "accept" file extension method. Old docs: http://docs.jquery.com/Plugins/Validation/Methods/accept
$.validator.addMethod( "extension", function( value, element, param ) {
	param = typeof param === "string" ? param.replace( /,/g, "|" ) : "png|jpe?g|gif";
	return this.optional( element ) || value.match( new RegExp( "\\.(" + param + ")$", "i" ) );
}, $.validator.format( "Please enter a value with a valid extension." ) );



/** App JS **/

if(typeof accounting !== 'undefined'){

    // Settings object that controls default parameters for library methods:
    accounting.settings = {
        currency: {
            symbol: 'R$', // default currency symbol is '$'
            format: '%s %v', // controls output: %s = symbol, %v = value/number (can be object: see below)
            decimal: ',', // decimal point separator
            thousand: '.', // thousands separator
            precision: 2 // decimal places
          },
        number: {
            precision: 0, // default precision on numbers is 0
            thousand: '.',
            decimal: ','
          }
      };
}

jQuery.validator.addMethod('cnpj', function(cnpj, element) {

    var numeros, digitos, soma, resultado, pos, tamanho,
        digitos_iguais = true;

    if (cnpj.length < 14 && cnpj.length > 15)
        return false;

    for (var i = 0; i < cnpj.length - 1; i++)
        if (cnpj.charAt(i) != cnpj.charAt(i + 1)) {
      digitos_iguais = false;
      break;
    }

    if (!digitos_iguais) {
      tamanho = cnpj.length - 2;
      numeros = cnpj.substring(0, tamanho);
      digitos = cnpj.substring(tamanho);
      soma = 0;
      pos = tamanho - 7;

      for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
      }

      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

      if (resultado != digitos.charAt(0))
          return false;

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;

      for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
      }

      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

      if (resultado != digitos.charAt(1))
          return false;

      return true;
    }

    return false;
  }, 'Informe um CNPJ v&aacute;lido.');


/**
* Valida o número do cartão de crédito utilizando a biblioteca do Stripe (jquery.payment)
*/
jQuery.validator.addMethod('cartaoNumeroValido', function(numero, element) {

    return $.payment.validateCardNumber(numero);

  }, 'O número do cartão digitado não é válido');

/**
* Valida o código de verificação do cartão de crédito utilizando a biblioteca do Stripe (jquery.payment)
*/
jQuery.validator.addMethod('cartaoCvc', function(numero, element) {

    return $.payment.validateCardCVC(numero);

  }, 'O código de verificação do cartão está incorreto');

/**
* Valida data de vencimento do cartão de crédito utilizando a biblioteca do Stripe (jquery.payment)
*/
jQuery.validator.addMethod('cartaoDataVencimento', function(numero, element) {

    let mes = $('#cartao_expiracao_mes').val();

    let ano = $('#cartao_expiracao_ano').val();

    return $.payment.validateCardExpiry(mes, ano);

  }, 'A data de vencimento do cartão deve ser uma data futura');

/**
 * Durante o cadastramento de um novo usuário, valida se o email informado
 * não existe no banco de dados (se é um email único).
 */
jQuery.validator.addMethod('emailEhUnico', function(email, element) {

    let result = false;
    let laravelToken = $('input[name=_token]').val();

    $.ajax({

        type: 'POST',
        async: false,
        url: '/cliente/valida-email-eh-unico',
        data: {_token: laravelToken, email: email},
        dataType: 'json',
        success: function(data) {
            result = data.emailEhUnico;
          }

      });

    return result;

  }, 'O email informado já existe em nosso cadastro.');

